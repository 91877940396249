import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import { Layout } from '@gik/ui/gik/Layout/Layout';
import { PageSection } from '@gik/ui/gik/PageSection';
import { FAQCard } from '../components/FAQCard';
import QuestionMarkCircleIcon from '@heroicons/react/solid/QuestionMarkCircleIcon';
import NewspaperIcon from '@heroicons/react/solid/NewspaperIcon';
import ChatAlt2Icon from '@heroicons/react/solid/ChatAlt2Icon';
import type { LandingPageFAQ } from '../types/LandingPageFAQ';
import { openIntercomWindow } from '@gik/core/utils/Intercom';
import routes from '@gik/core/routes';

/**
 * https://www.figma.com/design/FT2ninjhNiQOXfnOTaZfqy/%F0%9F%91%A8%F0%9F%8F%BC%E2%80%8D%F0%9F%8E%A4-Jade's-workspace-of-asset-creation?node-id=4757-8720&m=dev
 */
function FAQSectionComp() {
  const bem = useBemCN('faq-section');
  return (
    <PageSection asElement={'section'} {...bem(null, null, '!tw-pb-8 !tw-pt-20 !md:tw-pb-20')}>
      <Layout direction={'column'} gap={12}>
        <h2 className={titleClassNames.join(' ')}>Need help?</h2>

        <Layout direction={'column md:row'} align={'start md:space-evenly'} perpendicular={'stretch md:start'} gap={8}>
          {faqSection.map((faq, i) => (
            <FAQCard key={i} faq={faq} />
          ))}
        </Layout>
      </Layout>
    </PageSection>
  );
}

const titleClassNames = [
  'tw-m-0',
  'tw-text-neutral-900',
  'tw-text-4xl',
  'tw-font-heading',
  'tw-font-semibold',
  'tw-leading-tight',
  'md:tw-text-5xl',
];

const faqSection: LandingPageFAQ[] = [
  {
    title: 'Weekday Chat Support',
    content: 'Support every step of the way from our live team via chat.',
    icon: ChatAlt2Icon,
    iconClassName: 'tw-text-danger-500',
    linkTitle: 'Chat now',
    onClick: () => openIntercomWindow(),
  },
  {
    title: 'FAQs',
    content: 'Quick answers to the most commonly asked questions.',
    icon: QuestionMarkCircleIcon,
    iconClassName: 'tw-text-info-500',
    linkTitle: 'Learn more',
    linkUrl: routes.commonQuestions,
  },
  {
    title: 'Help Center',
    content: '24/7 support from our database of help articles and step-by-step walkthroughs.',
    icon: NewspaperIcon,
    iconClassName: 'tw-text-primary-500',
    linkTitle: 'Learn more',
    linkUrl: 'https://help.giveinkind.com/en/',
  },
];

export const FAQSection = withComponentErrorBoundary(FAQSectionComp);
