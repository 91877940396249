import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import { Layout } from '@gik/ui/gik/Layout/Layout';
import { CTACard } from '../components/CTACard';
import { PageSection } from '@gik/ui/gik/PageSection';
import type { LandingPageCTA } from '../types/LandingPageCTA';

export interface ICTASectionProps {
  ctas: LandingPageCTA[];
}

/**
 * https://www.figma.com/design/FT2ninjhNiQOXfnOTaZfqy/%F0%9F%91%A8%F0%9F%8F%BC%E2%80%8D%F0%9F%8E%A4-Jade's-workspace-of-asset-creation?node-id=4696-8640&m=dev
 */
function CTASectionComp({ ctas }: ICTASectionProps) {
  const bem = useBemCN('cta-section');
  return (
    <PageSection asElement={'section'} largePadding {...bem(null, null, '!tw-pt-8 !tw-pb-20 !md:tw-pt-20')}>
      <Layout
        direction={'column'}
        flex={1}
        style={{
          gap: '4rem',
        }}
      >
        {ctas.map((cta, i) => (
          <CTACard key={i} cta={cta} inverted={i % 2 !== 0} />
        ))}
      </Layout>
    </PageSection>
  );
}

export const CTASection = withComponentErrorBoundary(CTASectionComp);
