import { isEmpty } from './validator';

const VIDEO_EXTENSIONS = ['avi', 'mpg', 'mpeg', 'mov', 'mp4', 'm4v', 'ogg', 'ogv', 'webm', 'wmv'];
const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'webp'];

function isVideoExtension(extension: string): boolean {
  return VIDEO_EXTENSIONS.includes(extension);
}

function isImageExtension(extension: string): boolean {
  return IMAGE_EXTENSIONS.includes(extension);
}

function getExtension(filename: string): string {
  if (isEmpty(filename)) return null;
  return filename.split('.').pop().toLowerCase();
}

export function isVideo(filename: string): boolean {
  return isVideoExtension(getExtension(filename));
}

export function isImage(filename: string): boolean {
  return isImageExtension(getExtension(filename));
}

export function isURL(url: string): boolean {
  if (isEmpty(url)) return false;
  return url.startsWith('http://') || url.startsWith('https://');
}
