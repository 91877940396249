import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import { Layout } from '@gik/ui/gik/Layout/Layout';
import { PageSection } from '@gik/ui/gik/PageSection';
import { FeatureTile } from '../components/FeatureTile';
import { Button } from '@gik/ui/Button';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import routes from '@gik/core/routes';
import { openIntercomWindow } from '@gik/core/utils/Intercom';

/**
 * https://www.figma.com/design/FT2ninjhNiQOXfnOTaZfqy/%F0%9F%91%A8%F0%9F%8F%BC%E2%80%8D%F0%9F%8E%A4-Jade's-workspace-of-asset-creation?node-id=4827-8898&m=dev
 */
function FeaturesSectionComp() {
  const bem = useBemCN('features-section');

  const splitFeatures = [
    featuresSection.features.slice(0, featuresSection.features.length / 2),
    featuresSection.features.slice(featuresSection.features.length / 2),
  ];

  return (
    <PageSection asElement={'section'} largePadding {...bem(null, null, '!tw-pt-8 !tw-pb-20 !md:tw-pt-20')}>
      <Layout
        {...bem('container')}
        direction={'column'}
        align={'start'}
        perpendicular={'stretch'}
        // style={{
        //   gap: '4rem',
        // }}
      >
        <Layout direction={'column'} align={'start'} perpendicular={'center'} gap={4}>
          <h2 {...bem('heading', null, headingClassNames)}>Everything your support network needs</h2>
          <p {...bem('content', null, contentClassNames)}>
            The best tools for managing outreach efforts from near and far.
          </p>
        </Layout>

        <Layout
          {...bem('container', null, ['tw-bg-neutral-50', 'tw-p-4', 'md:tw-p-12'])}
          style={{
            borderRadius: 4,
          }}
          flex={1}
          gap={12}
          direction={'column md:row'}
          align={'space-between'}
          perpendicular={'start'}
        >
          {splitFeatures.map((features, i) => (
            <Layout key={i} direction={'column'} align={'start'} perpendicular={'start'} flex={1} gap={12}>
              {features.map((feature, i) => (
                <FeatureTile key={i} feature={feature} />
              ))}
            </Layout>
          ))}
        </Layout>

        <Layout direction={'column'} align={'start'} perpendicular={'center'} gap={6}>
          <h2 className={'tw-m-0 tw-text-center tw-font-heading tw-text-4xl tw-font-semibold tw-text-danger-500'}>
            All for free.
          </h2>
          <Button type={'a'} href="/create" append={<SvgIcon Icon={ChevronRightIcon} />}>
            Start a page
          </Button>
        </Layout>
      </Layout>
    </PageSection>
  );
}

const featuresSection = {
  features: [
    {
      icon: `${getAssetsUrl()}marketing/icons/calendar.png`,
      title: 'Care Calendar',
      features: [
        'Take turns providing meals, rides, childcare, and more. Schedule in-person and integrated gift card support. Automated reminders.',
      ],
      link: routes.mealTrain,
    },
    {
      icon: `${getAssetsUrl()}marketing/icons/updates.png`,
      title: 'Updates and Messages',
      features: ['Post updates instantly shared via email and social media.', 'Interact with community messages.'],
      link: routes.updates,
    },
    {
      icon: `${getAssetsUrl()}marketing/icons/wishlist.png`,
      title: 'Wishlist',
      features: [
        'Customize a list of helpful gifts purchased directly through your Page, or attach an Amazon Wishlist.',
      ],
      link: routes.organizeGiftCard,
    },
    {
      icon: `${getAssetsUrl()}marketing/icons/wallet.png`,
      title: 'Gift Card Wallet',
      features: [
        'One central wallet holds all gift cards for maximum flexibility and easy tracking, pooling, and swapping.',
      ],
      link: routes.organizeGiftCard,
    },
    {
      icon: `${getAssetsUrl()}marketing/icons/fundraising.png`,
      title: 'Connected Fundraising',
      features: ['Connect your CashApp, GoFundMe, PayPal, and Venmo for direct fundraising with no extra fees.'],
      link: routes.fundraising,
    },
    {
      icon: `${getAssetsUrl()}marketing/icons/support.png`,
      title: 'Live Chat Support',
      features: [
        'Chat with our Support Team Monday through Friday from 10am to 6pm Eastern for help with anything along the way.',
      ],
      onClick: () => openIntercomWindow(),
    },
  ],
};

export const FeaturesSection = withComponentErrorBoundary(FeaturesSectionComp);

const headingClassNames = [
  'tw-text-neutral-900',
  'tw-font-heading',
  'tw-m-0',

  'tw-text-4xl',
  'tw-font-semibold',
  'tw-text-center',

  'md:tw-font-semibold',
  'md:tw-text-5xl',
];
const contentClassNames = [
  'tw-m-0',
  'tw-text-neutral-800',

  'tw-text-gray-500',
  'tw-font-body',
  'tw-text-base',
  'tw-font-normal',
  'tw-leading-5',
  'tw-text-center',

  'md:tw-text-xl',
];
