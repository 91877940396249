import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import { PageSection } from '@gik/ui/gik/PageSection';
import { Layout } from '@gik/ui/gik/Layout/Layout';
import { TestimonialCard } from '../components/TestimonialCard';
import type { LandingPageTestimonial } from '../types/LandingPageTestimonial';
import { Button } from '@gik/ui/Button';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import { SvgIcon } from '@gik/ui/SvgIcon';
import ArrowLeftIcon from '@heroicons/react/outline/ArrowLeftIcon';
import ArrowRightIcon from '@heroicons/react/outline/ArrowRightIcon';

/**
 * https://www.figma.com/design/FT2ninjhNiQOXfnOTaZfqy/%F0%9F%91%A8%F0%9F%8F%BC%E2%80%8D%F0%9F%8E%A4-Jade's-workspace-of-asset-creation?node-id=4696-8722&m=dev
 */
function TestimonialsSectionComp() {
  const bem = useBemCN('testimonials-section');
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  const goToNext = () => {
    setCurrentIndex(prev => (prev + 1) % testimonials.length);
  };

  const goToPrev = () => {
    setCurrentIndex(prev => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <PageSection asElement={'section'} largePadding {...bem(null, null, '!tw-py-8 !md:tw-py-20')}>
      <Layout {...bem('container')} direction={'column'} align={'start'} perpendicular={'center'}>
        <Layout
          direction={'column'}
          align={'start'}
          perpendicular={'center'}
          gap={4}
          style={{
            maxWidth: '672px',
          }}
        >
          <h2 className={titleClassNames.join(' ')}>Testimonials</h2>
          <p className={paragraphClassNames.join(' ')}>
            What people like you have to say about organizing, receiving, and sending support through Give InKind
          </p>
        </Layout>

        <Layout
          {...bem('testimonials')}
          gap={8}
          direction={'column md:row'}
          align={'start'}
          perpendicular={'stretch md:start'}
        >
          {testimonials.map((testimonial, i) => (
            <TestimonialCard
              key={i}
              testimonial={testimonial}
              className={currentIndex !== i && '!tw-hidden !md:tw-flex'}
            />
          ))}
        </Layout>

        <Layout direction={'row'} gap={6} className={'!md:tw-hidden'}>
          <Button
            circle
            variant={'default-plain'}
            onClick={() => goToPrev()}
            prepend={<SvgIcon Icon={ArrowLeftIcon} />}
          />
          <Button
            circle
            variant={'default-plain'}
            onClick={() => goToNext()}
            prepend={<SvgIcon Icon={ArrowRightIcon} />}
          />
        </Layout>
      </Layout>
    </PageSection>
  );
}

const titleClassNames = [
  'tw-m-0',
  'tw-text-neutral-900',
  'tw-font-heading',
  'tw-font-semibold',
  'tw-leading-tight',
  'tw-text-center',
  'text-4xl',
  'md:tw-text-5xl',
];

const paragraphClassNames = [
  'tw-m-0',
  'tw-text-base',
  'tw-leading-5',
  'tw-text-neutral-700',
  'tw-font-body',
  'tw-font-normal',
  'tw-text-center',
  'md:tw-text-xl',
];

const testimonials: LandingPageTestimonial[] = [
  {
    title: 'Quickly Gather Support',
    content:
      '“Your website has provided an easy platform to quickly gather support for a family navigating an extremely difficult time.”',
    author: {
      avatarUrl: `${getAssetsUrl()}landing-pages/testimonials/Shannon.png`,
      name: 'Shannon',
      role: 'Page Supporter',
    },
  },
  {
    title: 'Easy to Navigate',
    content:
      '“People are loving the friendly and easy to navigate layout - thank you so much for providing a fantastic platform!”',
    author: {
      avatarUrl: `${getAssetsUrl()}landing-pages/testimonials/Noah.png`,
      name: 'Noah',
      role: 'Page Organizer',
    },
  },
  {
    title: 'Different Kinds of Support',
    content: '“We chose Give InKind because it allows us to help coordinate lots of different kinds of support.”',
    author: {
      avatarUrl: `${getAssetsUrl()}landing-pages/testimonials/Jennie.png`,
      name: 'Jennie',
      role: 'Page Organizer',
    },
  },
  {
    title: 'Always Helpful Chat',
    content:
      '“Your team have one of the best chat responses of anyone I’ve used. Always helpful, always solving problems.”',
    author: {
      avatarUrl: `${getAssetsUrl()}landing-pages/testimonials/David.png`,
      name: 'David',
      role: 'Page Organizer and Recipient',
    },
  },
  {
    title: 'Helpful Service',
    content:
      '“Just wanted to say how helpful this service is, as well as the help articles listed here. I found the solution to my issue easily. Thank you!”',
    author: {
      avatarUrl: `${getAssetsUrl()}landing-pages/testimonials/Thomas.png`,
      name: 'Thomas',
      role: 'Page Organizer',
    },
  },
  {
    title: 'Updates for Family and Friends',
    content:
      '“Our social worker encouraged us to create a Give InKind page, where family and friends can receive updates and provide support.”',
    author: {
      avatarUrl: `${getAssetsUrl()}landing-pages/testimonials/Joy.png`,
      name: 'Joy',
      role: 'Page Organizer',
    },
  },
  {
    title: 'Amazing Response',
    content: '“I was amazed by how easy it was to use, and even more amazed at the response from family and friends.”',
    author: {
      avatarUrl: `${getAssetsUrl()}landing-pages/testimonials/Mary.png`,
      name: 'Mary Lou',
      role: 'Page Organizer',
    },
  },
  {
    title: 'Only What the Family Needs',
    content:
      '“What a wonderful platform! Thank you for keeping us informed. It makes it a lot easier for us to make sure the family receives only what it needs.”',
    author: {
      avatarUrl: `${getAssetsUrl()}landing-pages/testimonials/Grace.png`,
      name: 'Grace',
      role: 'InKind Page Supporter',
    },
  },
  {
    title: 'Well Rounded Community Care',
    content:
      '“It’s not only wonderful to know that something well rounded like Give InKind exists, but also that our community cares so much about my friend and her family that they all wanted to help.”',
    author: {
      avatarUrl: `${getAssetsUrl()}landing-pages/testimonials/Karen.png`,
      name: 'Karen',
      role: 'InKind Page Organizer',
    },
  },
];

export const TestimonialsSection = withComponentErrorBoundary(TestimonialsSectionComp);
