import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import { Layout } from '@gik/ui/gik/Layout/Layout';
import { Avatar } from '@gik/ui/Avatar';
import type { LandingPageTestimonialAuthor } from '../types/LandingPageTestimonial';

export interface ITestimonialAuthorProps {
  author: LandingPageTestimonialAuthor;
}

/**
 * https://www.figma.com/design/FT2ninjhNiQOXfnOTaZfqy/%F0%9F%91%A8%F0%9F%8F%BC%E2%80%8D%F0%9F%8E%A4-Jade's-workspace-of-asset-creation?node-id=4696-8722&m=dev
 */
function TestimonialAuthorComp({ author: { avatarUrl, name, role } }: ITestimonialAuthorProps) {
  const bem = useBemCN('testimonial-author');
  return (
    <Layout {...bem()} direction={'row'} gap={3.5}>
      <Avatar rounded imageUrl={avatarUrl} />
      <Layout direction={'column'} align={'center'} perpendicular={'start'} gap={1}>
        <h4 className={nameClassNames.join(' ')}>{name}</h4>
        <p className={roleClassNames.join(' ')}>{role}</p>
      </Layout>
    </Layout>
  );
}

const nameClassNames = [
  'tw-m-0',
  'tw-text-neutral-900',
  'tw-font-heading',
  'tw-text-base',
  'tw-leading-none',
  'tw-font-bold',
];

const roleClassNames = ['tw-m-0', 'tw-text-neutral-700', 'tw-font-body', 'tw-text-sm', 'tw-leading-none'];

export const TestimonialAuthor = withComponentErrorBoundary(TestimonialAuthorComp);
