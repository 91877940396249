import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import { Layout } from '@gik/ui/gik/Layout/Layout';
import { TestimonialAuthor } from './TestimonialAuthor';
import type { LandingPageTestimonial } from '../types/LandingPageTestimonial';

export interface ITestimonialCardProps {
  testimonial: LandingPageTestimonial;
  className?: string;
}

/**
 * https://www.figma.com/design/FT2ninjhNiQOXfnOTaZfqy/%F0%9F%91%A8%F0%9F%8F%BC%E2%80%8D%F0%9F%8E%A4-Jade's-workspace-of-asset-creation?node-id=4696-8722&m=dev
 */
function TestimonialCardComp({ testimonial: { title, content, author }, className }: ITestimonialCardProps) {
  const bem = useBemCN('testimonial-card');
  return (
    <Layout
      {...bem(null, null, `tw-rounded tw-bg-neutral-50 md:tw-p-6${className ? ` ${className}` : ''}`)}
      direction={'column'}
      gap={1}
    >
      <h3 className={titleClassNames.join(' ')}>{title}</h3>
      <p className={paragraphClassNames.join(' ')}>{content}</p>
      <Layout direction={'row'} align={'center md:start tw-pt-8'} perpendicular={'center'}>
        <TestimonialAuthor author={author} />
      </Layout>
    </Layout>
  );
}

const titleClassNames = [
  'tw-m-0',
  'tw-text-neutral-900',
  'tw-font-heading',
  'tw-text-lg',
  'tw-font-bold',
  'tw-leading-none',
];

const paragraphClassNames = ['tw-m-0', 'tw-text-neutral-700', 'tw-font-body', 'tw-text-sm', 'tw-leading-6'];

export const TestimonialCard = withComponentErrorBoundary(TestimonialCardComp);
